import { getData } from './bffApiService';

export default {
  async getUsersByName(teamMemberName) {
    return getData('api/v1/Users/ByName', { name: teamMemberName });
  },
  async getUsersByCommonIds(commonIds) {
    return getData('api/v1/Users/ByCommonIds', { commonIds: commonIds }, { indexes: null });
  }
};
